// npm
import React, { memo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import {
  Box,
  BoxProps,
  Container,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'

// components
import InternalLink from '@atoms/links/InternalLink'
import ArtworkCategoryCard from '@molecules/cards/ArtworkCategoryCard'

interface SectionArtworkCategoriesProps extends BoxProps {
  heading: string
}

const Wrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  paddingTop: theme.spacing(7),
  paddingBottom: theme.spacing(7),
  overflow: 'hidden',
  [theme.breakpoints.up('sm')]: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(9),
    paddingBottom: theme.spacing(9),
  },
  [theme.breakpoints.up('lg')]: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
  },
  [theme.breakpoints.up('xl')]: {
    paddingTop: theme.spacing(11),
    paddingBottom: theme.spacing(11),
  },
}))

const StyledGridItem = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'stretch',
}))

const SectionArtworkCategories = ({
  heading,
  ...props
}: SectionArtworkCategoriesProps) => {
  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const data = useStaticQuery<Queries.SectionArtworkCategoriesQuery>(graphql`
    query SectionArtworkCategories {
      artworkLanding: datoCmsLanding(originalId: { eq: "63886058" }) {
        slug
      }
      allDatoCmsArtworkCategory(sort: { fields: position }) {
        edges {
          node {
            name
            model {
              apiKey
            }
            originalId
            slug
            artworkText
            xsImage: image {
              gatsbyImageData(
                width: 600
                layout: CONSTRAINED
                imgixParams: { ar: "300:360", fit: "crop", crop: "focalpoint" }
              )
            }
            lgImage: image {
              gatsbyImageData(
                width: 600
                layout: CONSTRAINED
                imgixParams: { ar: "320:360", fit: "crop", crop: "focalpoint" }
              )
            }
          }
        }
      }
    }
  `)
  return (
    <Wrapper component="section" {...props}>
      <Container maxWidth="lg">
        <Box
          typography="sectionHeadingLarge"
          component="h2"
          textAlign={{ xs: 'left', sm: 'center' }}
          mt={0}
        >
          <InternalLink slug={data.artworkLanding.slug}>{heading}</InternalLink>
        </Box>

        <Grid container spacing={mdDown ? 2 : 3}>
          {data.allDatoCmsArtworkCategory.edges.map((item: any) => {
            const category: Queries.DatoCmsArtworkCategory = item.node
            return (
              <StyledGridItem xs={12} sm={6} lg={4} key={category.originalId}>
                <ArtworkCategoryCard
                  artworkSlug={data.artworkLanding.slug}
                  category={category}
                />
              </StyledGridItem>
            )
          })}
        </Grid>
      </Container>
    </Wrapper>
  )
}

export default memo(SectionArtworkCategories)
