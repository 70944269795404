// npm
import React from 'react'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import { Box, BoxProps, alpha, styled, useTheme } from '@mui/material'

// components
import InternalLink from '@atoms/links/InternalLink'
import ImageHoverFrame from '@atoms/media/ImageHoverFrame'

interface Props {
  category: Queries.DatoCmsArtworkCategory
  wrapperProps?: BoxProps
  artworkSlug: string
}

const Wrapper = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    paddingLeft: theme.spacing(3),
  },
  borderLeft: '1px solid rgba(25,25,25,0.1)',
}))

const StyledGatsbyImage = styled(GatsbyImage)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    height: '0 !important',
    paddingTop: '120%',
  },
}))

const Name = styled(Box)(({ theme }) => ({
  fontFamily: theme.typography.fontFamilySerif,
  fontWeight: theme.typography.fontWeightLight,
  fontStyle: 'italic',
  fontSize: '3.2rem',
  letterSpacing: '0.1rem',
}))

const StyledInternalLink = styled(InternalLink)(({ theme }) => ({
  marginTop: theme.spacing(4),
  display: 'inline-block',
  paddingBottom: '3px',
  borderBottom: `1px solid ${alpha(theme.palette.primary.main, 0.8)}`,
  fontFamily: theme.typography.fontFamilySerif,
  fontWeight: theme.typography.fontWeightLight,
  fontSize: '1.5rem',
  letterSpacing: '0.1rem',
  textTransform: 'uppercase',
  lineHeight: 1,
  whiteSpace: 'nowrap',
}))

const ArtworkCategoryCard = ({
  category,
  wrapperProps,
  artworkSlug,
}: Props) => {
  const theme = useTheme()
  // @ts-ignore
  const images = withArtDirection(getImage(category.lgImage.gatsbyImageData), [
    {
      media: `(max-width: ${theme.breakpoints.values.lg}px)`,
      image: getImage(category.xsImage.gatsbyImageData),
    },
  ])

  return (
    <Wrapper {...wrapperProps}>
      <InternalLink
        slug={category.slug}
        prefixSlug={artworkSlug}
        apiKey={category.model.apiKey}
      >
        <ImageHoverFrame>
          <StyledGatsbyImage image={images} alt="" />
        </ImageHoverFrame>
        <Name my={2}>{category.name}</Name>
      </InternalLink>
      <Box>{category.artworkText}</Box>
      <StyledInternalLink
        slug={category.slug}
        prefixSlug={artworkSlug}
        apiKey={category.model.apiKey}
      >
        View More
      </StyledInternalLink>
    </Wrapper>
  )
}

export default ArtworkCategoryCard
